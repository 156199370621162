<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="充电站" prop="station_id" :rules="rules">
        <SelectStation v-model="formData.station_id"></SelectStation>
      </el-form-item>
      <el-form-item label="充电桩名称" prop="name" :rules="rules">
        <el-input style="width: 400px" v-model="formData.name" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否快充" prop="is_fast_charge" :rules="rules">
        <el-select style="width: 400px" v-model="formData.is_fast_charge" clearable placeholder="请选择" size="small">
          <el-option label="否" :value="0"> </el-option>
          <el-option label="是" :value="1"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SelectStation from '@/views/components/select-station.vue'
import { addAPI, editAPI, getDetailAPI } from './api'
export default {
  name: 'AddOrEdit',
  components: { SelectStation },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        station_id: '',
        name: '',
        pile_number: '',
        pile_type: '',
        gun_quantity: '',
        protocol_version: null,
        program_version: null,
        network_type: null,
        sim_card: null,
        mobile_operator: null,
        pile_gun: [{ gun_number: '' }, { gun_number: '' }],
        is_fast_charge: 0
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .gun {
    .item {
      display: flex;
    }
  }
}
</style>
